import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
const buttonStyles = {

}
const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_ifaQwgtYcxjfF6H7Q0ZWRw25")
  }
  return stripePromise
}
const Checkout = () => {
  const [loading, setLoading] = useState(false)
  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1HMFKCLcHQF0XMmEuUJFNuq9", quantity: 1 }],
      successUrl: `https://boldrewrite.com/success/`,
      cancelUrl: `https://boldrewrite.com/`,
    })
    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }
  return (
    <a
      className='button is-black is-medium'
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      Proceed to Checkout
    </a>
  )
}
export default Checkout