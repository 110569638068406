import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Checkout from '../Checkout'
import redditimg from '../../assets/img/redditimg.png'
import { Link } from 'gatsby'


const OrderPageTemplate = ({ title, subtitle, meta_title, meta_description, testimonials }) => {
  return <div>
    <Helmet
      bodyAttributes={{
        class: 'bold-hero-bg'
      }}
    >
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body pb-0'>
        <div className='column is-three-fifths is-offset-one-fifth'>
          <h1 className='title is-size-1 has-text-weight-bold'>
            {title}
          </h1>
        </div>
      </div>
    </section>

    <section className='section column is-three-fifths is-offset-one-fifth'>

      <div className='columns is-variable is-8 my-4 mobile-reverse-order'> 

        <div className='column'>
          <h4 className='has-text-weight-bold is-size-4'>This is not just advice, this is <em>actual written work</em> that will reinvigorate any page on your website.</h4>
          <p className='has-text-grey my-4'>Right now</p>
          <p>💰 Order your Bold Rewrite and checkout securely with Stripe</p>
          <p>🧾 Receive your receipt and order confirmation</p>
          <p>🏎 We followup with questions that'll get us up to speed</p>
          <p>🤔 Answer questions about your startup and its challenges</p>

          <p className='has-text-grey my-4'>In 72 hours</p>
          <p>📊 Receive a status report on your existing marketing comms</p>
          <p>🙌 Copy and paste improvements for your website copy</p>
          <p>⭐️ Finished work based on your specific challenges</p>
          <p>🥝 A Fresh + authentic perspective on your startup</p>
          <p>🤳 Your own private resource for reference</p>

          <p className='has-text-grey my-4'>In 4 days</p>
          <p>🕺 You write us an awesome review, or get your money back</p>

        </div>

        <div className='column'>
          <div className='box py-6 has-text-centered'>
            <Checkout />
            <p className='has-text-grey mt-6'>Securely handled by Stripe</p>
          </div>
        </div>

      </div>

    </section>

  </div>
}

OrderPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  testimonials: PropTypes.array,
}

export default OrderPageTemplate
